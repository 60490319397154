import React, { Fragment, useContext, useState } from 'react'
import { Tooltip, Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { GlobalContext } from '../context/GlobalContext'
import { AuthContext } from '../context/AuthContext';
import UsersAddDialogForm from './UsersAddDialogForm';

export const UsersAddButton = ({ getUsers }) => {

    const { language, gtColors } = useContext(GlobalContext)
    const { user } = useContext(AuthContext)
    const [ openDialog, setOpenDialog ] = useState(false)

    return (
        <div>
            <UsersAddDialogForm open={openDialog} setOpen={setOpenDialog} user={user} getUsers={getUsers}/>
            <Tooltip onClick={()=>setOpenDialog(true)} 
                     placement="left" 
                     title={language === 'FR' ? "Ajouter un utilisateur" : "Add User"}>
                <Fab color="primary" size="large" variant="circular">
                    <AddIcon />
                </Fab>
            </Tooltip>
        </div>
    )
}
