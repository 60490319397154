import React, {useState, useContext} from 'react';
import {Tabs, Tab }from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';


export default function AnalysisPageTab( {tabValue, setTabValue} ) {
    
    const { language } = useContext(GlobalContext)

    const handleChange = (event, newValue) => {

        setTabValue(newValue);
    };

    return (
        <Tabs 
            value={tabValue} 
            onChange={handleChange} 
            centered
        >
            <Tab label={language === "FR" ? "Lancer une Analyse / un chargement" : "Launch Analysis / Upload Files"} />
            <Tab label={language === "FR" ? "Historique Lancement Analyses" : "See Analysis Launch History"} />
            <Tab label={language === "FR" ? "Historique Chargement Fichiers" : "See Files Upload History"} />
        </Tabs>
    );
}