import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';


import React, { useContext } from 'react'
import { useHistory } from 'react-router';

import { AuthContext } from '../context/AuthContext';
import { GlobalContext } from '../context/GlobalContext';

const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
    },
}));

    
export default function NavigationBar({user}) {

    const classes = useStyles();
    const history = useHistory();
    
    const { logoutUser } = useContext(AuthContext)
    const { language } = useContext(GlobalContext)

    function handleLogoutButtonClick() {
        logoutUser()
        history.replace("/login")
    }

    
    return (
        <AppBar position="sticky" color="primary">
            <Toolbar style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <div style={{display:"flex", alignItems:"center"}}>
                    <MenuIcon fontSize='large' style={{cursor:"pointer"}} onClick={() => history.push("/menu")}/>
                    <Button color="inherit" variant="text" style={{margin:"4px 4px 4px 4px"}} onClick={() => history.push("/analysis")}>{language === "EN" ? "Analysis" : "Analyses"}</Button>
                    {(user && user.role !== 'USER') && 
                        <Button color="inherit" variant="text" style={{margin:"4px 4px 4px 4px"}} onClick={() => history.push("/users")}>{language === "EN" ? "Users" : "Utilisateurs"}</Button>
                    }
                    {/* <Button color="inherit" variant="text" style={{margin:"4px 4px 4px 4px"}} onClick={() => history.push("/dashboard")}>{language === "EN" ? "Dashboard" : "Tableau de Bord"}</Button> */}
                    <Button color="inherit" variant="text" style={{margin:"4px 4px 4px 4px"}} onClick={() => history.push("/help")}>{language === "EN" ? "Help" : "Aide"}</Button>
                </div>
                <div style={{display:"flex", alignItems:"center"}}>
                    <PersonIcon style={{margin:"0px 5px 0px 20px"}}/>
                    <Typography variant="h6" color="inherit" noWrap className={classes.title}>{user && user.first_name + " " + user.last_name}</Typography>
                    <Button color="inherit" variant="outlined" style={{marginLeft:"10px"}} onClick={handleLogoutButtonClick}>{language === "EN" ? "Logout" : "Se Déconnecter"}</Button>                    
                </div>                
            </Toolbar>
            {!user && <LinearProgress color="primary" />}
        </AppBar>
    )
}
