import React, {useContext, useState, useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, LinearProgress} from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';
import { AuthContext } from '../context/AuthContext';
import { getLaunchRecords } from '../utils/utilities';
import AnalysisPageFilesUploadHistoryRow from './AnalysisPageFilesUploadHistoryRow';


export default function AnalysisPageFilesUploadHistoryTable() {

    const { language, gtColors } = useContext(GlobalContext)
    const { user } = useContext(AuthContext)

    const [ loading, setLoading ] = useState(false)
    const [ launchRecords, setLaunchRecords ] = useState([])
    const [ visibleUploadFiles, setVisibleUploadFiles ] = useState([])
    const [ filesLabelsOverrides, setFilesLabelsOverrides ] = useState([])

    async function getRecords() {
        setLoading(true)
        const records = await getLaunchRecords()
        setLaunchRecords(records.filter((rec) => rec.launch_type !== "analysis"))
        setLoading(false)
    }

    function getUploadFileLabel(fileCode, lang) {
        // first check if overriden labeld
        for (let file of user.company.upload_files_labels_overrides) {
            if (file.code === fileCode) {
                return lang.toLowerCase() === "fr" ? file.new_label_fr : file.new_label_en
            }
        }
        // look for default label if it has not been overrided
        for (let file of user.company.upload_files) {
            if (file.code === fileCode) {
                return lang.toLowerCase() === "fr" ? file.label_fr : file.label_en
            }
        }
    }

    useEffect(() => {
        getRecords()        
    }, [])

    useEffect(() => {        
        try {
            setVisibleUploadFiles(user.company.upload_files.map(item => item.code))
            setFilesLabelsOverrides(user.company.upload_files_labels_overrides)
        } catch {
            /// no thing: keep empty list
        }
    }, [user])

    return (
        <TableContainer component={Paper} elevation={4}>
            {loading && <LinearProgress color='gtOrange'/>}
            <Table sx={{ minWidth: 750 }} aria-label="simple table" size='small'>
                <TableHead>
                    <TableRow style={{backgroundColor:gtColors.primary}}>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Date Création' : 'Creation Date'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Référence' : 'Reference'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Auteur' : 'Author'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Type' : 'Type'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Statut' : 'Status'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Détail Statut' : 'Status Detail'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? "Trasmis Par" : 'Submitted By'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? "Processus" : 'Precess'}</TableCell>
                        {visibleUploadFiles.includes("FIC001") && <TableCell style={{color:"white"}}>{language === 'FR' ? getUploadFileLabel("FIC001", "fr") : getUploadFileLabel("FIC001", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC002") && <TableCell style={{color:"white"}}>{language === 'FR' ? getUploadFileLabel("FIC002", "fr") : getUploadFileLabel("FIC002", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC003") && <TableCell style={{color:"white"}}>{language === 'FR' ? getUploadFileLabel("FIC003", "fr") : getUploadFileLabel("FIC003", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC004") && <TableCell style={{color:"white"}}>{language === 'FR' ? getUploadFileLabel("FIC004", "fr") : getUploadFileLabel("FIC004", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC005") && <TableCell style={{color:"white"}}>{language === 'FR' ? getUploadFileLabel("FIC005", "fr") : getUploadFileLabel("FIC005", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC006") && <TableCell style={{color:"white"}}>{language === 'FR' ? getUploadFileLabel("FIC006", "fr") : getUploadFileLabel("FIC006", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC007") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC007", "fr") : getUploadFileLabel("FIC007", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC008") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC008", "fr") : getUploadFileLabel("FIC008", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC009") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC009", "fr") : getUploadFileLabel("FIC009", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC010") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC010", "fr") : getUploadFileLabel("FIC010", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC011") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC011", "fr") : getUploadFileLabel("FIC011", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC012") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC012", "fr") : getUploadFileLabel("FIC012", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC013") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC013", "fr") : getUploadFileLabel("FIC013", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC014") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC014", "fr") : getUploadFileLabel("FIC014", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC015") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC015", "fr") : getUploadFileLabel("FIC015", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC016") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC016", "fr") : getUploadFileLabel("FIC016", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC017") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC017", "fr") : getUploadFileLabel("FIC017", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC018") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC018", "fr") : getUploadFileLabel("FIC018", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC019") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC019", "fr") : getUploadFileLabel("FIC019", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC020") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC020", "fr") : getUploadFileLabel("FIC020", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC021") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC021", "fr") : getUploadFileLabel("FIC021", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC022") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC022", "fr") : getUploadFileLabel("FIC022", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC023") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC023", "fr") : getUploadFileLabel("FIC023", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC024") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC024", "fr") : getUploadFileLabel("FIC024", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC025") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC025", "fr") : getUploadFileLabel("FIC025", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC026") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC026", "fr") : getUploadFileLabel("FIC026", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC027") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC027", "fr") : getUploadFileLabel("FIC027", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC028") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC028", "fr") : getUploadFileLabel("FIC028", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC029") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC029", "fr") : getUploadFileLabel("FIC029", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC030") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC030", "fr") : getUploadFileLabel("FIC030", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC031") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC031", "fr") : getUploadFileLabel("FIC031", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC032") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC032", "fr") : getUploadFileLabel("FIC032", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC033") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC033", "fr") : getUploadFileLabel("FIC033", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC034") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC034", "fr") : getUploadFileLabel("FIC034", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC035") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC035", "fr") : getUploadFileLabel("FIC035", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC036") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC036", "fr") : getUploadFileLabel("FIC036", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC037") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC037", "fr") : getUploadFileLabel("FIC037", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC038") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC038", "fr") : getUploadFileLabel("FIC038", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC039") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC039", "fr") : getUploadFileLabel("FIC039", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC040") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC040", "fr") : getUploadFileLabel("FIC040", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC041") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC041", "fr") : getUploadFileLabel("FIC041", "en") }</TableCell>}
                        {visibleUploadFiles.includes("FIC042") && <TableCell style={{color:"white"}}>{language === "FR" ? getUploadFileLabel("FIC042", "fr") : getUploadFileLabel("FIC042", "en") }</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {launchRecords.map((record) => (                        
                        <AnalysisPageFilesUploadHistoryRow key={record.id + "-" + record.reference} record={record} language={language} visibleUploadFiles={visibleUploadFiles}/>     
                    ))}                    
                </TableBody>
            </Table>
            {loading && <LinearProgress color='gtOrange'/>}
        </TableContainer>
    );
}
