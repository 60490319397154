import React, { useRef, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Paper, TextField, Button, Typography, LinearProgress, Alert } from '@mui/material'
import * as loginStyles from '../styles/loginPageStyles'
import { AuthContext } from '../context/AuthContext'
import { GlobalContext } from '../context/GlobalContext'
import Footer from '../components/Footer'

export default function Login() {

    const history = useHistory()
    const { loginUser, loadingLogin, loginError, loginErrorMessage, setLoginError, user } = useContext(AuthContext)
    const { language, setLanguage } = useContext(GlobalContext)

    const usernameInputRef = useRef()
    const passwordInputRef = useRef()

    async function handleLoginClick(e) {
        
        // prevents from button default behaviour
        e.preventDefault()

        // to prevent making multiple requests while waiting for a response from server 
        if (loadingLogin) {
            return
        }

        // retrive username and password values from input form
        const username = usernameInputRef.current.value
        const password = passwordInputRef.current.value
        
        // login user
        await loginUser(username, password)
    }

    useEffect(() => {
        if (user) {

            const goToPath = localStorage.getItem("currentPath")
            
            if (!goToPath | goToPath==='/login') {
                history.push("/menu")
            } else {
                history.push(goToPath)
            }
            
            if (user.preferred_language) {
                setLanguage(user.preferred_language)
            }
        }

    }, [user])

    return (
        <div style={loginStyles.backgroundStyle}>
            <div style= {loginStyles.layerStyle}>
                <Typography variant='h2' color="primary" align='center' style={loginStyles.titleStyle}>
                    {language === "FR" ? "Solution Data Analytics" : "Data Analytics Solution"}
                </Typography>
                <Paper elevation={5} style={loginStyles.paperStyle}>
                    <Grid container> 
                        <Grid item xs={12}>
                            <Typography variant='h3' align='center' style={loginStyles.paperHeadStyle}>
                                {language === "FR" ? "Accès Sécurisé" : "Restricted Access"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{padding:"12px 100px", marginTop:"25px"}}>
                            <TextField fullWidth id="outlined" label={language === "FR" ? "Identifiant" :"Username"} inputRef={usernameInputRef}/>
                        </Grid>
                        <Grid item xs={12} style={{padding:"12px 100px"}}>
                            <TextField fullWidth id="outlined-password-input" label={language === "FR" ? "Mot de passe":"Password"} type="password" inputRef={passwordInputRef}/>
                        </Grid>
                        <Grid item xs={12} style={{padding:"12px 100px"}}>
                            <Button fullWidth variant="contained" style={{height:"40x"}} onClick={handleLoginClick}>
                                {language === "FR" ? "S'identifier" : "Login"} 
                            </Button>
                        </Grid>
                        <Grid item xs={12} style={{padding:"12px 100px"}}>
                            {loadingLogin && <LinearProgress />}
                            {loginError && <Alert severity="error" color="primary"  onClose={() => {setLoginError(false)}}> {loginErrorMessage} </Alert>}
                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </div>
        </div>
    )
}
