import React, { useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import { AuthContext } from '../context/AuthContext';
import NavigationBar  from '../components/NavigationBar'


export default function MainLayout(props) {

    const { user } = useContext(AuthContext)

    return (
        <Fragment>
            <Helmet>
                <title>Grant Thornton - Data Analytics Solution</title>
            </Helmet>
            <NavigationBar user={user}/>
            <main style={{marginBottom:"60px"}}>
                {props.children}
            </main>
            <Footer />
        </Fragment> 
    )
}