import React, {useContext} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, LinearProgress} from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';
import UsersTableRow from './UsersTableRow';

export default function UsersTable( { usersList, getUsers, loading } ) {

    const { language, gtColors } = useContext(GlobalContext)

    return (
        <TableContainer component={Paper} elevation={4}>
            {loading && <LinearProgress color='gtOrange'/>}
            <Table sx={{ minWidth: 750 }} aria-label="simple table" size='small'>
                <TableHead>
                    <TableRow style={{backgroundColor:gtColors.primary}}>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Identifiant' : 'Username'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Nom' : 'Last Name'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Prénom' : 'Fisrt Name'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Email' : 'Email'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Poste' : 'Title'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Organisation' : 'Company'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Rôle' : 'Role'}</TableCell>
                        <TableCell style={{color:"white"}} align='center'>{language === 'FR' ? 'Action' : 'Action'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usersList.map( (userItem) => (
                        <UsersTableRow key={userItem.id + "-" + userItem.username} userItem={userItem} getUsers={getUsers}/>     
                    ))}                    
                </TableBody>
            </Table>
            {loading && <LinearProgress color='gtOrange'/>}
        </TableContainer>
  );
}
