import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'

import App from './App';
import './index.css';

import { ThemeProvider } from '@mui/material/styles';
import { grantThorntonTheme } from './themes/grantThorntonTheme'

import { AuthContextProvider } from './context/AuthContext'
import { GlobalContextProvider } from './context/GlobalContext'


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={grantThorntonTheme}>
      <BrowserRouter>
        <GlobalContextProvider>
          <AuthContextProvider>
              <App/>
          </AuthContextProvider>
        </GlobalContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


