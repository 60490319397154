import React, {useState, useContext} from 'react'
import { Typography, Container} from '@mui/material'
import AnalysisPageTab from '../components/AnalysisPageTab'
import AnalysisPageLaunchAnalysis from '../components/AnalysisPageLaunchAnalysis'
import AnalysisPageAnalysisLaunchHistoryTable from '../components/AnalysisPageAnalysisLaunchHistoryTable'
import AnalysisPageFilesUploadHistoryTable from '../components/AnalysisPageFilesUploadHistoryTable'
import { GlobalContext } from '../context/GlobalContext'

export default function AnalysisPage() {

    const { language } = useContext(GlobalContext)

    const [ tabValue, setTabValue ] = useState(0)

    return (
        <div style={{marginLeft:"50px", marginRight:"50px"}}>
            <Typography variant='h3' align="center" marginTop={4} fontWeight="bold">
                {language === 'FR' ? 'Analyses' : "Analysis Page"}
            </Typography>
            <div style={{width:"100%", marginTop:"30px"}}>
                <AnalysisPageTab tabValue={tabValue} setTabValue={setTabValue}/>
            </div>
            <div style={{width:"100%", marginTop:"30px", display:"flex", justifyContent:"center"}}>
                {tabValue == 0 ? <AnalysisPageLaunchAnalysis /> : null}
                {tabValue == 1 ? <AnalysisPageAnalysisLaunchHistoryTable /> : null}
                {tabValue == 2 ? <AnalysisPageFilesUploadHistoryTable /> : null}
            </div>
        </div>
    )
}
