import axios from "axios";

const axiosInstance = axios.create()

// intercept response and logout user by refreshing browser wish will triger user auth and redirect user to login page if no more authenticated (token no more valid)
axiosInstance.interceptors.response.use((res) => {
        
        return res

    }, (err) => {

        if (err.response.status === 401) {
            console.log("Token no longer valid - user deconnected and redirected to the login page.")
            window.location.reload()  // when page refreshed, user will be redirected to login page if token is no longer valid
        }       
        
        return Promise.reject(err)
    }
)


// intercept request in order to add Token in the Authorization Header
axiosInstance.interceptors.request.use((req) => {        
    // retrive token from local storage    
    const token = localStorage.getItem("Token")

    // add headers to the request
    req.headers = {
        "Authorization": `Token ${token}`,
        "Content-Type":"application/json",
        "accept": "application/json"
    }

    // return request
    return req
})

export default axiosInstance