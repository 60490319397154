import React, { useEffect, useState, useContext } from 'react'
import { Container, Typography, Grid, LinearProgress } from '@mui/material'
import { getFaqs } from '../utils/utilities'
import HelpPageCard from '../components/HelpPageCard'
import { GlobalContext } from '../context/GlobalContext'

export default function HelpPage() {
    
    const { language } = useContext(GlobalContext)

    const [ faqList, setFaqList ] = useState([])
    const [ loading, setLoading ] = useState(true)

    async function getData() {
        setLoading(true)
        const faqs = await getFaqs()
        setFaqList(faqs)
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Container maxWidth="md">
            <Typography variant='h3' align="center" marginTop={4} fontWeight="bold">
                { language === "FR" ? "Aide / FAQ" : "Help / FAQ Page"}
            </Typography>
            
            <Grid container style={{minWidth:"80%", marginTop:"50px"}} justifyContent="flex-start" spacing={3}>
                <Grid item xs={12}>
                    { loading && <LinearProgress color='gtOrange'/> }
                </Grid>

                {faqList.map(item => (
                    <Grid item xs={12} key={item.id}>
                        <HelpPageCard data={item}/>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
