import React, { useContext, useState } from 'react';
import {DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Button, LinearProgress, Alert } from '@mui/material'
import { GlobalContext } from '../context/GlobalContext';
import { deleteUser } from '../utils/utilities';

export default function UsersDeleteConfirmDialog( { open, setOpen, username, getUsers } ) {

    const { language, gtColors } = useContext(GlobalContext)
    const [ loading, setLoading ] = useState(false)
    const [ deleteSuccess, setDeleteSuccess ] = useState()
    const [ deteleAPImessage, setDeteleAPImessage ] = useState("")

    const handleClose = () => {
        setDeleteSuccess()
        setDeteleAPImessage("")
        setOpen(false);
    };

    const confirmButtonClick = async () => {
        
        setLoading(true)
        const res = await deleteUser(username)
        setLoading(false)
        
        setDeleteSuccess(res.success)
        setDeteleAPImessage(res.message)
        
        setTimeout(() => {
            setDeleteSuccess()
            setDeteleAPImessage("")
            setOpen(false)
            if (res.success) {
                getUsers()
            }
        }, 2000)
    }

    return (
    <div>
        <Dialog maxWidth="md" open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" color={gtColors.orange}>
                {language === 'FR' ? ` Confirmer la suppression de l'utilisateur (${username})` : `Delete User Confirmation (${username})`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {language === 'FR' ? `Etês-vous sûr de vouloir supprimer l'utilisateur '${username}' ?` 
                                    : `You are about to delete the user '${username}'. Are you sure ?`}
                </DialogContentText>
            </DialogContent>
            {loading && <LinearProgress color='gtOrange'/>}
            <DialogActions style={{marginRight:"25px", marginBottom:"10px"}}>
                <Button onClick={handleClose} color='gtOrange' variant='outlined'>
                    {language === 'FR' ? "Annuler" : "Cancel"}
                </Button>
                <Button onClick={confirmButtonClick} autoFocus color='gtOrange' variant='contained'>
                    {language === 'FR' ? "Confirmer" : "Confirm"}
                </Button>
            </DialogActions>
            {deleteSuccess !== undefined && (
            <Alert severity={deleteSuccess ? "success" : "error"}>
                {language === "FR" ? 
                    (deleteSuccess ? "Utilisateur supprimé avec succès" : deteleAPImessage ) 
                    : (deleteSuccess ? "User deleted successfully" : deteleAPImessage)
                }
            </Alert>)
            }
        </Dialog>
    </div>
    );
}