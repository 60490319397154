import React, { useState, useContext, Fragment, useEffect } from 'react'
import { AuthContext } from '../context/AuthContext'
import { GlobalContext } from '../context/GlobalContext'
import { createLaunchRecord, uploadFile, addTimestampToFileName } from '../utils/utilities';
import AnalysisPageSnackBarAlert from './AnalysisPageSnackBarAlert';

import { Grid, TextField, Checkbox, FormControlLabel, Box, Chip, FormGroup, Radio, RadioGroup, LinearProgress, FormControl, Button, MenuItem, Select, InputLabel, Alert  } from '@mui/material';
import FileUploader from './FileUploader';


export default function AnalysisPageLaunchAnalysis() {

    const { language } = useContext(GlobalContext)
    const { user } = useContext(AuthContext)

    const [ loading, setLoading ] = useState(false)
    const [ alertOpen, setAlertOpen ] = useState(false)
    const [ alertType, setAlertType ] = useState()

    const [ launchType, setLaunchType] = useState("analysis")

    const [ analysisDate, setAnalysisDate ] = useState(new Date().toISOString().slice(0, 10))
    const [ analysisHour, setAnalysisHour ] = useState(new Date().toISOString().slice(11, 16))
    const [ startDate, setStartDate ] = useState("")
    const [ endDate, setEndDate ] = useState("")
    const [ controlsTobeDone, setControlsToBeDone ] = useState([])
    const [ companiesToAnalyse, setCompaniesToAnalyse ] = useState("")
    const [ samplingPct, setSamplingPct ] = useState("")
    const [ samplingThresholdLow, setSamplingThresholdLow ] = useState("")
    const [ samplingThresholdHigh, setSamplingThresholdHigh ] = useState("")
    const [ runNow, setRunNow ] = useState(false)
    
    const [ submittedBy, setSubmittedBy ] = useState("")
    const [ process, setProcces ] = useState("")
    
    const [ visibleUploadFiles, setVisibleUploadFiles ] = useState([])

    const [ fileGrandLivre, setFileGrandLivre ] = useState()
    const [ fileBalanceGen, setFileBalanceGen ] = useState()
    const [ fileBaseTiers, setFileBaseTiers ] = useState()
    const [ fileFichierEntite, setFileFichierEntite ] = useState()
    const [ filePaiements, setFilePaiements ] = useState()
    const [ fileMotsCles, setFileMotsCles ] = useState()
    const [ fileFichiersBancaires, setFileFichiersBancaires ] = useState()
    const [ filePaysRisque, setFilePaysRisque ] = useState()
    const [ fileLettrages, setFileLettrages ] = useState()
    const [ fileBalanceClient, setFileBalanceClient ] = useState()
    const [ fileBalanceFournisseur, setFileBalanceFournisseur ] = useState()
    const [ fileTiersRisque, setFileTiersRisque ] = useState()
    const [ fileFichierPaie, setFileFichierPaie ] = useState()
    const [ filePostesNonSoldes, setFilePostesNonSoldes ] = useState()
    const [ fileNotesDeFrais, setFileNotesDeFrais ] = useState()
    const [ fileHistoriqueModifications, setFileHistoriqueModifications ] = useState()
    const [ fileStocks, setFileStocks ] = useState()
    const [ fileArticles, setFileArticles ] = useState()
    const [ fileContrats, setFileContrats ] = useState()
    const [ fileCommandes, setFileCommandes ] = useState()
    const [ fileReceptions, setFileReceptions ] = useState()
    const [ fileLivraisons, setFileLivraisons ] = useState()
    const [ fileMouvementsStock, setFileMouvementsStock ] = useState()
    const [ fileFactures, setFileFactures ] = useState()
    const [ fileEmployes, setFileEmployes ] = useState()
    const [ fileUtilisateurs, setFileUtilisateurs ] = useState()
    const [ fileHabilitations, setFileHabilitations ] = useState()
    const [ fileOrganisation, setFileOrganisation ] = useState()
    const [ fileDelegationPouvoirs, setFileDelegationPouvoirs ] = useState()
    const [ fileDeclaration, setFileDeclaration ] = useState()
    const [ fileImmobilisations, setFileImmobilisations ] = useState()
    const [ fileMatriceSOD, setFileMatriceSOD ] = useState()
    const [ fileAutre1, setFileAutre1 ] = useState()
    const [ fileAutre2, setFileAutre2 ] = useState()
    const [ fileAutre3, setFileAutre3 ] = useState()
    const [ fileAutre4, setFileAutre4 ] = useState()
    const [ fileAutre5, setFileAutre5 ] = useState()
    const [ fileAutre6, setFileAutre6 ] = useState()
    const [ fileAutre7, setFileAutre7 ] = useState()
    const [ fileAutre8, setFileAutre8 ] = useState()
    const [ fileAutre9, setFileAutre9 ] = useState()
    const [ fileAutre10, setFileAutre10 ] = useState()

    const [ uploadProgressGrandLivre, setUploadProgressGrandLivre ] = useState()
    const [ uploadProgressBalanceGen, setUploadProgressBalanceGen ] = useState()
    const [ uploadProgressBaseTiers, setUploadProgressBaseTiers ] = useState()
    const [ uploadProgressFichierEntite, setUploadProgressFichierEntite ] = useState()
    const [ uploadProgressPaiements, setUploadProgressPaiements ] = useState()
    const [ uploadProgressMotsCles, setUploadProgressMotsCles ] = useState()
    const [ uploadProgressFichiersBancaires, setUploadProgressFichiersBancaires ] = useState()
    const [ uploadProgressPaysRisque, setUploadProgressPaysRisque ] = useState()
    const [ uploadProgressLettrages, setUploadProgressLettrages ] = useState()
    const [ uploadProgressBalanceClient, setUploadProgressBalanceClient ] = useState()
    const [ uploadProgressBalanceFournisseur, setUploadProgressBalanceFournisseur ] = useState()
    const [ uploadProgressTiersRisque, setUploadProgressTiersRisque ] = useState()
    const [ uploadProgressFichierPaie, setUploadProgressFichierPaie ] = useState()
    const [ uploadProgressPostesNonSoldes, setUploadProgressPostesNonSoldes ] = useState()
    const [ uploadProgressNotesDeFrais, setUploadProgressNotesDeFrais ] = useState()
    const [ uploadProgressHistoriqueModifications, setUploadProgressHistoriqueModifications ] = useState()
    const [ uploadProgressStocks, setUploadProgressStocks ] = useState()
    const [ uploadProgressArticles, setUploadProgressArticles ] = useState()
    const [ uploadProgressContrats, setUploadProgressContrats ] = useState()
    const [ uploadProgressCommandes, setUploadProgressCommandes ] = useState()
    const [ uploadProgressReceptions, setUploadProgressReceptions ] = useState()
    const [ uploadProgressLivraisons, setUploadProgressLivraisons ] = useState()
    const [ uploadProgressMouvementsStock, setUploadProgressMouvementsStock ] = useState()
    const [ uploadProgressFactures, setUploadProgressFactures ] = useState()
    const [ uploadProgressEmployes, setUploadProgressEmployes ] = useState()
    const [ uploadProgressUtilisateurs, setUploadProgressUtilisateurs ] = useState()
    const [ uploadProgressHabilitations, setUploadProgressHabilitations ] = useState()
    const [ uploadProgressOrganisation, setUploadProgressOrganisation ] = useState()
    const [ uploadProgressDelegationPouvoirs, setUploadProgressDelegationPouvoirs ] = useState()
    const [ uploadProgressDeclaration, setUploadProgressDeclaration ] = useState()
    const [ uploadProgressImmobilisations, setUploadProgressImmobilisations ] = useState()
    const [ uploadProgressMatriceSOD, setUploadProgressMatriceSOD ] = useState()
    const [ uploadProgressAutre1, setUploadProgressAutre1 ] = useState()
    const [ uploadProgressAutre2, setUploadProgressAutre2 ] = useState()
    const [ uploadProgressAutre3, setUploadProgressAutre3 ] = useState()
    const [ uploadProgressAutre4, setUploadProgressAutre4 ] = useState()
    const [ uploadProgressAutre5, setUploadProgressAutre5 ] = useState()
    const [ uploadProgressAutre6, setUploadProgressAutre6 ] = useState()
    const [ uploadProgressAutre7, setUploadProgressAutre7 ] = useState()
    const [ uploadProgressAutre8, setUploadProgressAutre8 ] = useState()
    const [ uploadProgressAutre9, setUploadProgressAutre9 ] = useState()
    const [ uploadProgressAutre10, setUploadProgressAutre10 ] = useState()

    async function handleSubmitButtonClick() {

        setLoading(true)

        const date = new Date().toISOString().slice(0,10).replaceAll("-","")
        const time = new Date().toISOString().slice(11,19).replaceAll(":","")
        const timestamp = Date.now()

        const body = {
            
            launch_type : launchType,
            reference : user.company ? `${user.company.gt_id}_${date}_${time}` : `${user.username}_${date}_${time}`,
            status: "running",
            
            user_username : user.username,
            user_role : user.role,
            
            company : user.company ? user.company.name : null,
            company_id : user.company ? user.company.id : null,
            company_gt_id : user.company ? user.company.gt_id : null,
            
            analysis_date : launchType !== "filesUpload" ? analysisDate : null,
            analysis_hour : launchType !== "filesUpload" ? analysisHour : null,
            run_immediately : runNow,
            start_date : launchType !== "filesUpload" ? startDate : null,
            end_date : launchType !== "filesUpload" ? endDate : null,
            companies_to_analyse : launchType !== "filesUpload" ? companiesToAnalyse : null,
            controls : launchType !== "filesUpload" ? controlsTobeDone.join(",") : null,
            sampling_pct : launchType !== "filesUpload" ? samplingPct : null,
            sampling_threshold_low : launchType !== "filesUpload" ? samplingThresholdLow : null,
            sampling_threshold_high : launchType !== "filesUpload" ? samplingThresholdHigh : null,            
            
            submitted_by : submittedBy || null,
            process : process || null,

            grand_livre_file_name : (launchType !== 'analysis' && fileGrandLivre) ? addTimestampToFileName(fileGrandLivre.name, timestamp) : null,
            balance_generale_file_name : (launchType !== 'analysis' && fileBalanceGen) ? addTimestampToFileName(fileBalanceGen.name, timestamp) : null,
            base_tiers_file_name : (launchType !== 'analysis' && fileBaseTiers) ? addTimestampToFileName(fileBaseTiers.name, timestamp) : null,
            fichier_entites_file_name : (launchType !== 'analysis' && fileFichierEntite) ? addTimestampToFileName(fileFichierEntite.name, timestamp) : null,
            paiements_file_name : (launchType !== 'analysis' && filePaiements) ? addTimestampToFileName(filePaiements.name, timestamp) : null,
            mots_cles_file_name : (launchType !== 'analysis' && fileMotsCles) ? addTimestampToFileName(fileMotsCles.name, timestamp) : null,
            fichiers_bancaires_file_name : (launchType !== 'analysis' && fileFichiersBancaires) ? addTimestampToFileName(fileFichiersBancaires.name, timestamp) : null,
            pays_risque_file_name : (launchType !== 'analysis' && filePaysRisque) ? addTimestampToFileName(filePaysRisque.name, timestamp) : null,
            lettrages_file_name : (launchType !== 'analysis' && fileLettrages) ? addTimestampToFileName(fileLettrages.name, timestamp) : null,
            balance_client_file_name : (launchType !== 'analysis' && fileBalanceClient) ? addTimestampToFileName(fileBalanceClient.name, timestamp) : null,
            balance_fournisseur_file_name : (launchType !== 'analysis' && fileBalanceFournisseur) ? addTimestampToFileName(fileBalanceFournisseur.name, timestamp) : null,
            tiers_risque_file_name : (launchType !== 'analysis' && fileTiersRisque) ? addTimestampToFileName(fileTiersRisque.name, timestamp) : null,
            fichier_paie_file_name : (launchType !== 'analysis' && fileFichierPaie) ? addTimestampToFileName(fileFichierPaie.name, timestamp) : null,
            postes_non_soldes_file_name : (launchType !== 'analysis' && filePostesNonSoldes) ? addTimestampToFileName(filePostesNonSoldes.name, timestamp) : null,
            note_de_frais_file_name : (launchType !== 'analysis' && fileNotesDeFrais) ? addTimestampToFileName(fileNotesDeFrais.name, timestamp) : null,
            historique_modifications_file_name : (launchType !== 'analysis' && fileHistoriqueModifications) ? addTimestampToFileName(fileHistoriqueModifications.name, timestamp) : null,
            stocks_file_name : (launchType !== 'analysis' && fileStocks) ? addTimestampToFileName(fileStocks.name, timestamp) : null,
            articles_file_name : (launchType !== 'analysis' && fileArticles) ? addTimestampToFileName(fileArticles.name, timestamp) : null,
            contrats_file_name : (launchType !== 'analysis' && fileContrats) ? addTimestampToFileName(fileContrats.name, timestamp) : null,
            commandes_file_name : (launchType !== 'analysis' && fileCommandes) ? addTimestampToFileName(fileCommandes.name, timestamp) : null,
            receptions_file_name : (launchType !== 'analysis' && fileReceptions) ? addTimestampToFileName(fileReceptions.name, timestamp) : null,
            livraisons_file_name : (launchType !== 'analysis' && fileLivraisons) ? addTimestampToFileName(fileLivraisons.name, timestamp) : null,
            mouvements_stock_file_name : (launchType !== 'analysis' && fileMouvementsStock) ? addTimestampToFileName(fileMouvementsStock.name, timestamp) : null,
            factures_file_name : (launchType !== 'analysis' && fileFactures) ? addTimestampToFileName(fileFactures.name, timestamp) : null,
            employes_file_name : (launchType !== 'analysis' && fileEmployes) ? addTimestampToFileName(fileEmployes.name, timestamp) : null,
            utilisateurs_file_name : (launchType !== 'analysis' && fileUtilisateurs) ? addTimestampToFileName(fileUtilisateurs.name, timestamp) : null,
            habilitations_file_name : (launchType !== 'analysis' && fileHabilitations) ? addTimestampToFileName(fileHabilitations.name, timestamp) : null,
            organisation_file_name : (launchType !== 'analysis' && fileOrganisation) ? addTimestampToFileName(fileOrganisation.name, timestamp) : null,
            delegation_pouvoirs_file_name : (launchType !== 'analysis' && fileDelegationPouvoirs) ? addTimestampToFileName(fileDelegationPouvoirs.name, timestamp) : null,
            declaration_file_name : (launchType !== 'analysis' && fileDeclaration) ? addTimestampToFileName(fileDeclaration.name, timestamp) : null,
            immobilisations_file_name : (launchType !== 'analysis' && fileImmobilisations) ? addTimestampToFileName(fileImmobilisations.name, timestamp) : null,
            matrice_sod_file_name : (launchType !== 'analysis' && fileMatriceSOD) ? addTimestampToFileName(fileMatriceSOD.name, timestamp) : null,
            autre_1_file_name : (launchType !== 'analysis' && fileAutre1) ? addTimestampToFileName(fileAutre1.name, timestamp) : null,
            autre_2_file_name : (launchType !== 'analysis' && fileAutre2) ? addTimestampToFileName(fileAutre2.name, timestamp) : null,
            autre_3_file_name : (launchType !== 'analysis' && fileAutre3) ? addTimestampToFileName(fileAutre3.name, timestamp) : null,
            autre_4_file_name : (launchType !== 'analysis' && fileAutre4) ? addTimestampToFileName(fileAutre4.name, timestamp) : null,
            autre_5_file_name : (launchType !== 'analysis' && fileAutre5) ? addTimestampToFileName(fileAutre5.name, timestamp) : null,
            autre_6_file_name : (launchType !== 'analysis' && fileAutre6) ? addTimestampToFileName(fileAutre6.name, timestamp) : null,
            autre_7_file_name : (launchType !== 'analysis' && fileAutre7) ? addTimestampToFileName(fileAutre7.name, timestamp) : null,
            autre_8_file_name : (launchType !== 'analysis' && fileAutre8) ? addTimestampToFileName(fileAutre8.name, timestamp) : null,
            autre_9_file_name : (launchType !== 'analysis' && fileAutre9) ? addTimestampToFileName(fileAutre9.name, timestamp) : null,
            autre_10_file_name : (launchType !== 'analysis' && fileAutre10) ? addTimestampToFileName(fileAutre10.name, timestamp) : null,

        }

        const res = await createLaunchRecord(body)
        
        if (launchType !== 'analysis'){
                      
            await uploadFile(fileGrandLivre, setUploadProgressGrandLivre, {timestamp:timestamp})
            await uploadFile(fileBalanceGen, setUploadProgressBalanceGen, {timestamp:timestamp})
            await uploadFile(fileBaseTiers, setUploadProgressBaseTiers, {timestamp:timestamp})
            await uploadFile(fileFichierEntite, setUploadProgressFichierEntite, {timestamp:timestamp})
            await uploadFile(filePaiements, setUploadProgressPaiements, {timestamp:timestamp})
            await uploadFile(fileMotsCles, setUploadProgressMotsCles, {timestamp:timestamp})
            await uploadFile(fileFichiersBancaires, setUploadProgressFichiersBancaires, {timestamp:timestamp})
            await uploadFile(filePaysRisque, setUploadProgressPaysRisque, {timestamp:timestamp})
            await uploadFile(fileLettrages, setUploadProgressLettrages, {timestamp:timestamp})
            await uploadFile(fileBalanceClient, setUploadProgressBalanceClient, {timestamp:timestamp})
            await uploadFile(fileBalanceFournisseur, setUploadProgressBalanceFournisseur, {timestamp:timestamp})
            await uploadFile(fileTiersRisque, setUploadProgressTiersRisque, {timestamp:timestamp})
            await uploadFile(fileFichierPaie, setUploadProgressFichierPaie, {timestamp:timestamp})
            await uploadFile(filePostesNonSoldes, setUploadProgressPostesNonSoldes, {timestamp:timestamp})
            await uploadFile(fileNotesDeFrais, setUploadProgressNotesDeFrais, {timestamp:timestamp})
            await uploadFile(fileHistoriqueModifications, setUploadProgressHistoriqueModifications, {timestamp:timestamp})
            await uploadFile(fileStocks, setUploadProgressStocks, {timestamp:timestamp})
            await uploadFile(fileArticles, setUploadProgressArticles, {timestamp:timestamp})
            await uploadFile(fileContrats, setUploadProgressContrats, {timestamp:timestamp})
            await uploadFile(fileCommandes, setUploadProgressCommandes, {timestamp:timestamp})
            await uploadFile(fileReceptions, setUploadProgressReceptions, {timestamp:timestamp})
            await uploadFile(fileLivraisons, setUploadProgressLivraisons, {timestamp:timestamp})
            await uploadFile(fileMouvementsStock, setUploadProgressMouvementsStock, {timestamp:timestamp})
            await uploadFile(fileFactures, setUploadProgressFactures, {timestamp:timestamp})
            await uploadFile(fileEmployes, setUploadProgressEmployes, {timestamp:timestamp})
            await uploadFile(fileUtilisateurs, setUploadProgressUtilisateurs, {timestamp:timestamp})
            await uploadFile(fileHabilitations, setUploadProgressHabilitations, {timestamp:timestamp})
            await uploadFile(fileOrganisation, setUploadProgressOrganisation, {timestamp:timestamp})
            await uploadFile(fileDelegationPouvoirs, setUploadProgressDelegationPouvoirs, {timestamp:timestamp})
            await uploadFile(fileDeclaration, setUploadProgressDeclaration, {timestamp:timestamp})
            await uploadFile(fileImmobilisations, setUploadProgressImmobilisations, {timestamp:timestamp})
            await uploadFile(fileMatriceSOD, setUploadProgressMatriceSOD, {timestamp:timestamp})
            await uploadFile(fileAutre1, setUploadProgressAutre1, {timestamp:timestamp})
            await uploadFile(fileAutre2, setUploadProgressAutre2, {timestamp:timestamp})
            await uploadFile(fileAutre3, setUploadProgressAutre3, {timestamp:timestamp})
            await uploadFile(fileAutre4, setUploadProgressAutre4, {timestamp:timestamp})
            await uploadFile(fileAutre5, setUploadProgressAutre5, {timestamp:timestamp})
            await uploadFile(fileAutre6, setUploadProgressAutre6, {timestamp:timestamp})
            await uploadFile(fileAutre7, setUploadProgressAutre7, {timestamp:timestamp})
            await uploadFile(fileAutre8, setUploadProgressAutre8, {timestamp:timestamp})
            await uploadFile(fileAutre9, setUploadProgressAutre9, {timestamp:timestamp})
            await uploadFile(fileAutre10, setUploadProgressAutre10, {timestamp:timestamp})
        }
        
        if (res.success) {
            console.log(res)
            setAlertOpen(true)
            setAlertType("success")
        }

        setLoading(false)
    }

    function handleControlsListChange(e) {        
        setControlsToBeDone(e.target.value)        
    }

    function getUploadFileLabel(fileCode, lang) {
        
        // first check if overriden labeld
        for (let file of user.company.upload_files_labels_overrides) {
            if (file.code === fileCode) {
                return lang.toLowerCase() === "fr" ? file.new_label_fr : file.new_label_en
            }
        }

        // look for default label if it has not been overrided
        for (let file of user.company.upload_files) {
            if (file.code === fileCode) {
                return lang.toLowerCase() === "fr" ? file.label_fr : file.label_en
            }
        }
    }

    useEffect(() => {
        if (runNow) {
            setAnalysisDate(null)
            setAnalysisHour(null)
        } else {
            setAnalysisDate(new Date().toISOString().slice(0, 10))
            setAnalysisHour(new Date().toISOString().slice(11, 16))
        }
    }, [runNow])


    useEffect(() => {
        
        try {
            setVisibleUploadFiles(user.company.upload_files.map(item => item.code))
        } catch {
            /// no thing: keep empty list
        }

    }, [user])

    const helperTextFr = "Saisir le code société à analyser, tel qu'il apparait dans les données. Pour une analyse sur plusieurs sociétés, séparer les codes par une virgule (,). Exemple: CODE1,CODE2,CODE3 "
    const helperTextEn = "Provide the code of the company to be analysed as displayed in the data. If the analysis concern multiple companies, use a comma (,) to separate the codes. Example: CODE1,CODE2,CODE3"

    return (
        <div style={{width:"600px", marginTop:"10px"}}>
            {loading && <LinearProgress color='gtOrange'/>}
            <AnalysisPageSnackBarAlert open={alertOpen} setOpen={setAlertOpen} alertType={alertType}/>
            <div style={{marginBottom:"50px", display:"flex", justifyContent:"center"}}>
                <RadioGroup row aria-label="gender" name="row-radio-buttons-group" value={launchType} onChange={e => setLaunchType(e.target.value)}>
                    <FormControlLabel value="analysis" control={<Radio />} label= {language === "FR" ? "Lancer une analyse" : "Launch an Analysis"}/>
                    <FormControlLabel value="filesUpload" control={<Radio />} label= {language === "FR" ? "Chargement de fichiers" : "Upload Files"}/>
                    <FormControlLabel value="both" control={<Radio />} label= {language === "FR" ? "Les deux" : "Both"}/>
                </RadioGroup>
            </div>
            <Grid container columns={12} justifyContent="flex-start" alignItems="center" spacing={2}>
                {launchType !== "filesUpload" && (
                <Fragment>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="analysis_date"
                        label={language === "FR" ? "Date d'exécution planifiée" :"Planned Execution Date" }
                        type="date"
                        value={runNow ? "1900-01-01" : analysisDate}
                        disabled={runNow}
                        onChange={(e) => setAnalysisDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="analysis_time"
                        label={language === "FR" ? "Heure d'exécution planifiée" :"Planned Execution Time" }
                        type="time"
                        value={runNow ? "00:00" : analysisHour}
                        disabled={runNow}
                        onChange={(e) => setAnalysisHour(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel 
                            control={<Checkbox/>} 
                            label={language === "FR" ? "Exécuter immédiatement" :"Execute Immediately"}
                            checked={runNow}
                            onChange={e => setRunNow(e.target.checked)}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="start_date"
                        label={language === "FR" ? "Date début d'analyse" :"Analysis Start Date" }
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="end_date"
                        label={language === "FR" ? "Date fin d'analyse" :"Analysis End Date" }
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="analysis_companies"
                        label={language === "FR" ? "Sociétés à analyser" :"Orginisations to be Analyzed" }
                        helperText={language === "FR" ? helperTextFr :helperTextEn }
                        FormHelperTextProps={{style:{}}}
                        type="text"
                        variant="standard"
                        value={companiesToAnalyse}
                        onChange={(e) => setCompaniesToAnalyse(e.target.value)}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label" style={{marginLeft:"-15px"}}>{language === "FR" ? "Contrôles à effectuer" :"Controls to be done" }</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            type="Select"
                            variant="standard"
                            value={controlsTobeDone}
                            onChange={handleControlsListChange}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                  ))}
                                </Box>
                              )}
                        >   
                            {user && user.company && user.company.controls && user.company.controls.map((elem, idx) => (
                                <MenuItem key={elem.code} value={elem.code}>
                                    {elem.code + " - " + elem.decription}
                                </MenuItem> 
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id="sampling_pct"
                        label={language === "FR" ? "% Echantillonnage" :"Sampling %" }
                        type="number"
                        variant="standard"
                        value={samplingPct}
                        onChange={(e) => setSamplingPct(e.target.value)}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id="sampling_pct_low"
                        label={language === "FR" ? "Seuil bas" :"Sample Threshold - Low" }
                        type="number"
                        variant="standard"
                        value={samplingThresholdLow}
                        onChange={(e) => setSamplingThresholdLow(e.target.value)}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id="sampling_pct_high"
                        label={language === "FR" ? "Seuil haut" :"Sample Threshold - High" }
                        type="number"
                        variant="standard"
                        value={samplingThresholdHigh}
                        onChange={(e) => setSamplingThresholdHigh(e.target.value)}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                    />
                </Grid>
                </Fragment>)}
                {launchType === "filesUpload" && (
                <Fragment>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="submitted_by"
                            label={language === "FR" ? "Transmis par" :"Submitted By" }
                            type="text"
                            variant="outlined"
                            value={submittedBy}
                            onChange={(e) => setSubmittedBy(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            id="process"
                            label={language === "FR" ? "Processus" :"Process" }
                            type="text"
                            variant="outlined"
                            value={process}
                            onChange={(e) => setProcces(e.target.value)}
                        />
                    </Grid>
                </Fragment>
                )}
                {launchType !== "analysis" && ( 
                <Fragment>
                {(visibleUploadFiles.length === 0 || visibleUploadFiles.includes("FIC000")) && 
                    <Grid item xs={12} style={{marginTop:"10px"}}>
                        <Alert severity="warning">{language === "FR" ? "Selon votre société, vous n'avez aucun fichier à charger !" : "According to your company, there are no files to be uploaded !"}</Alert>
                    </Grid>
                }
                {visibleUploadFiles.includes("FIC001") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC001", "fr") : getUploadFileLabel("FIC001", "en")}
                            setFile= {setFileGrandLivre}
                            uploadProgress= {uploadProgressGrandLivre}    
                        />
                    </Grid>
                }
                {visibleUploadFiles.includes("FIC002") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC002", "fr") : getUploadFileLabel("FIC002", "en")}
                            setFile= {setFileBalanceGen}
                            uploadProgress= {uploadProgressBalanceGen} 
                        />
                    </Grid>
                }
                {visibleUploadFiles.includes("FIC003") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC003", "fr") : getUploadFileLabel("FIC003", "en")}
                            setFile= {setFileBaseTiers}
                            uploadProgress= {uploadProgressBaseTiers} 
                        />
                    </Grid>
                }
                {visibleUploadFiles.includes("FIC004") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC004", "fr") : getUploadFileLabel("FIC004", "en")}
                            setFile= {setFileFichierEntite}
                            uploadProgress= {uploadProgressFichierEntite} 
                        />
                    </Grid>
                }
                {visibleUploadFiles.includes("FIC005") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC005", "fr") : getUploadFileLabel("FIC005", "en")}
                            setFile= {setFilePaiements}
                            uploadProgress= {uploadProgressPaiements} 
                        />
                    </Grid>
                }
                {visibleUploadFiles.includes("FIC006") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC006", "fr") : getUploadFileLabel("FIC006", "en")}
                            setFile= {setFileMotsCles}
                            uploadProgress= {uploadProgressMotsCles} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC007") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC007", "fr") : getUploadFileLabel("FIC007", "en")}
                            setFile= {setFileFichiersBancaires}
                            uploadProgress= {uploadProgressFichiersBancaires} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC008") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC008", "fr") : getUploadFileLabel("FIC008", "en")}
                            setFile= {setFilePaysRisque}
                            uploadProgress= {uploadProgressPaysRisque} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC009") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC009", "fr") : getUploadFileLabel("FIC009", "en")}
                            setFile= {setFileLettrages}
                            uploadProgress= {uploadProgressLettrages} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC010") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC010", "fr") : getUploadFileLabel("FIC010", "en")}
                            setFile= {setFileBalanceClient}
                            uploadProgress= {uploadProgressBalanceClient} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC011") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC011", "fr") : getUploadFileLabel("FIC011", "en")}
                            setFile= {setFileBalanceFournisseur}
                            uploadProgress= {uploadProgressBalanceFournisseur} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC012") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC012", "fr") : getUploadFileLabel("FIC012", "en")}
                            setFile= {setFileTiersRisque}
                            uploadProgress= {uploadProgressTiersRisque} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC013") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC013", "fr") : getUploadFileLabel("FIC013", "en")}
                            setFile= {setFileFichierPaie}
                            uploadProgress= {uploadProgressFichierPaie} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC014") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC014", "fr") : getUploadFileLabel("FIC014", "en")}
                            setFile= {setFilePostesNonSoldes}
                            uploadProgress= {uploadProgressPostesNonSoldes} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC015") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC015", "fr") : getUploadFileLabel("FIC015", "en")}
                            setFile= {setFileNotesDeFrais}
                            uploadProgress= {uploadProgressNotesDeFrais} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC016") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC016", "fr") : getUploadFileLabel("FIC016", "en")}
                            setFile= {setFileHistoriqueModifications}
                            uploadProgress= {uploadProgressHistoriqueModifications} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC017") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC017", "fr") : getUploadFileLabel("FIC017", "en")}
                            setFile= {setFileStocks}
                            uploadProgress= {uploadProgressStocks} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC018") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC018", "fr") : getUploadFileLabel("FIC018", "en")}
                            setFile= {setFileArticles}
                            uploadProgress= {uploadProgressArticles} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC019") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC019", "fr") : getUploadFileLabel("FIC019", "en")}
                            setFile= {setFileContrats}
                            uploadProgress= {uploadProgressContrats} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC020") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC020", "fr") : getUploadFileLabel("FIC020", "en")}
                            setFile= {setFileCommandes}
                            uploadProgress= {uploadProgressCommandes} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC021") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC021", "fr") : getUploadFileLabel("FIC021", "en")}
                            setFile= {setFileReceptions}
                            uploadProgress= {uploadProgressReceptions} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC022") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC022", "fr") : getUploadFileLabel("FIC022", "en")}
                            setFile= {setFileLivraisons}
                            uploadProgress= {uploadProgressLivraisons} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC023") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC023", "fr") : getUploadFileLabel("FIC023", "en")}
                            setFile= {setFileMouvementsStock}
                            uploadProgress= {uploadProgressMouvementsStock} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC024") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC024", "fr") : getUploadFileLabel("FIC024", "en")}
                            setFile= {setFileFactures}
                            uploadProgress= {uploadProgressFactures} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC025") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC025", "fr") : getUploadFileLabel("FIC025", "en")}
                            setFile= {setFileEmployes}
                            uploadProgress= {uploadProgressEmployes} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC026") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC026", "fr") : getUploadFileLabel("FIC026", "en")}
                            setFile= {setFileUtilisateurs}
                            uploadProgress= {uploadProgressUtilisateurs} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC027") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC027", "fr") : getUploadFileLabel("FIC027", "en")}
                            setFile= {setFileHabilitations}
                            uploadProgress= {uploadProgressHabilitations} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC028") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC028", "fr") : getUploadFileLabel("FIC028", "en")}
                            setFile= {setFileOrganisation}
                            uploadProgress= {uploadProgressOrganisation} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC029") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC029", "fr") : getUploadFileLabel("FIC029", "en")}
                            setFile= {setFileDelegationPouvoirs}
                            uploadProgress= {uploadProgressDelegationPouvoirs} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC030") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC030", "fr") : getUploadFileLabel("FIC030", "en")}
                            setFile= {setFileDeclaration}
                            uploadProgress= {uploadProgressDeclaration} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC031") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC031", "fr") : getUploadFileLabel("FIC031", "en")}
                            setFile= {setFileImmobilisations}
                            uploadProgress= {uploadProgressImmobilisations} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC032") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC032", "fr") : getUploadFileLabel("FIC032", "en")}
                            setFile= {setFileMatriceSOD}
                            uploadProgress= {uploadProgressMatriceSOD} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC033") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC033", "fr") : getUploadFileLabel("FIC033", "en")}
                            setFile= {setFileAutre1}
                            uploadProgress= {uploadProgressAutre1} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC034") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC034", "fr") : getUploadFileLabel("FIC034", "en")}
                            setFile= {setFileAutre2}
                            uploadProgress= {uploadProgressAutre2} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC035") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC035", "fr") : getUploadFileLabel("FIC035", "en")}
                            setFile= {setFileAutre3}
                            uploadProgress= {uploadProgressAutre3} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC036") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC036", "fr") : getUploadFileLabel("FIC036", "en")}
                            setFile= {setFileAutre4}
                            uploadProgress= {uploadProgressAutre4} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC037") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC037", "fr") : getUploadFileLabel("FIC037", "en")}
                            setFile= {setFileAutre5}
                            uploadProgress= {uploadProgressAutre5} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC038") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC038", "fr") : getUploadFileLabel("FIC038", "en")}
                            setFile= {setFileAutre6}
                            uploadProgress= {uploadProgressAutre6} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC039") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC039", "fr") : getUploadFileLabel("FIC039", "en")}
                            setFile= {setFileAutre7}
                            uploadProgress= {uploadProgressAutre7} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC040") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC040", "fr") : getUploadFileLabel("FIC040", "en")}
                            setFile= {setFileAutre8}
                            uploadProgress= {uploadProgressAutre8} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC041") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC041", "fr") : getUploadFileLabel("FIC041", "en")}
                            setFile= {setFileAutre9}
                            uploadProgress= {uploadProgressAutre9} 
                        />
                    </Grid>
                }                    
                {visibleUploadFiles.includes("FIC042") && 
                    <Grid item xs={12}>
                        <FileUploader 
                            inputLabel= {language === 'FR' ? getUploadFileLabel("FIC042", "fr") : getUploadFileLabel("FIC042", "en")}
                            setFile= {setFileAutre10}
                            uploadProgress= {uploadProgressAutre10} 
                        />
                    </Grid>
                }                    
                </Fragment>)}
                {(visibleUploadFiles.length !== 0 || launchType !== "filesUpload") && 
                    <Grid item xs={12} marginTop={2}>
                        <Button 
                            variant='contained' 
                            fullWidth  onClick={handleSubmitButtonClick} disabled={loading}>
                            {language === "FR" ? "Envoyer" : "submit"}
                        </Button>
                    </Grid>
                }
            </Grid>
            <br/>
            {loading && <LinearProgress color='gtOrange'/>}
        </div>
    )
}
