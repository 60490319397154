import React from 'react';
import { TableCell, TableRow } from '@mui/material';

export default function AnalysisPageFilesUploadHistoryRow({ record, language, visibleUploadFiles }) {


    return (
        <TableRow hover>
            <TableCell > {record.creation_date.slice(0, 10) + " " +record.creation_date.slice(11, 16)} </TableCell>
            <TableCell > {record.reference} </TableCell>
            <TableCell > {record.user_username} </TableCell>
            <TableCell > {record.launch_type === "filesUpload" ? (language === 'FR' ? 'Chargement Fichiers': "Files Upload") : (language === 'FR' ? 'Analyses + Chargement': "Analysis + Files Upload")} </TableCell>
            <TableCell > {record.status} </TableCell>
            <TableCell > {record.status_detail} </TableCell>
            <TableCell > {record.submitted_by} </TableCell>
            <TableCell > {record.process} </TableCell>
            {visibleUploadFiles.includes("FIC001") && <TableCell > {record.grand_livre_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC002") && <TableCell > {record.balance_generale_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC003") && <TableCell > {record.base_tiers_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC004") && <TableCell > {record.fichier_entites_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC005") && <TableCell > {record.paiements_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC006") && <TableCell > {record.mots_cles_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC007") && <TableCell > {record.fichiers_bancaires_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC008") && <TableCell > {record.pays_risque_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC009") && <TableCell > {record.lettrages_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC010") && <TableCell > {record.balance_client_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC011") && <TableCell > {record.balance_fournisseur_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC012") && <TableCell > {record.tiers_risque_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC013") && <TableCell > {record.fichier_paie_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC014") && <TableCell > {record.postes_non_soldes_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC015") && <TableCell > {record.note_de_frais_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC016") && <TableCell > {record.historique_modifications_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC017") && <TableCell > {record.stocks_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC018") && <TableCell > {record.articles_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC019") && <TableCell > {record.contrats_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC020") && <TableCell > {record.commandes_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC021") && <TableCell > {record.receptions_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC022") && <TableCell > {record.livraisons_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC023") && <TableCell > {record.mouvements_stock_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC024") && <TableCell > {record.factures_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC025") && <TableCell > {record.employes_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC026") && <TableCell > {record.utilisateurs_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC027") && <TableCell > {record.habilitations_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC028") && <TableCell > {record.organisation_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC029") && <TableCell > {record.delegation_pouvoirs_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC030") && <TableCell > {record.declaration_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC031") && <TableCell > {record.immobilisations_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC032") && <TableCell > {record.matrice_sod_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC033") && <TableCell > {record.autre_1_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC034") && <TableCell > {record.autre_2_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC035") && <TableCell > {record.autre_3_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC036") && <TableCell > {record.autre_4_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC037") && <TableCell > {record.autre_5_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC038") && <TableCell > {record.autre_6_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC039") && <TableCell > {record.autre_7_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC040") && <TableCell > {record.autre_8_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC041") && <TableCell > {record.autre_9_file_name} </TableCell>}
            {visibleUploadFiles.includes("FIC042") && <TableCell > {record.autre_10_file_name} </TableCell>}
        </TableRow>
    );
}
