import React, { useContext }  from 'react';
import {TableCell, TableRow } from '@mui/material';
import { AuthContext } from '../context/AuthContext';

export default function AnalysisPageAnalysisLaunchHistoryRow({ record, language }) {

    const { user } = useContext(AuthContext)

    // const redirect_url = "https://app.powerbi.com/groups/682125f5-050c-468b-b78d-b8a2a2a281b5/list?clientSideAuth=0&refreshAccessToken=true&noSignUpCheck=1&UPN=pbiadmin@gtbrsdata.onmicrosoft.com"
    const redirect_url = user.company.dashboard_url || "http://46.101.131.198:8088/"

    return (
        <TableRow hover>
            <TableCell > {record.creation_date.slice(0, 10) + " " +record.creation_date.slice(11, 16)} </TableCell>
            <TableCell > <a href={redirect_url} target='_blank' rel='noreferrer'>{record.reference}</a> </TableCell>
            <TableCell > {record.user_username} </TableCell>
            <TableCell > {record.launch_type === "analysis" ? (language === 'FR' ? 'Analyse': "Analysis") : (language === 'FR' ? 'Analyses + Chargement': "Analysis + Files Upload")} </TableCell>
            <TableCell > {record.status} </TableCell>
            <TableCell > {record.status_detail} </TableCell>
            <TableCell > {record.analysis_date ? record.analysis_date + " " + record.analysis_hour : null} </TableCell>
            <TableCell > {record.run_immediately ? (language === 'FR' ? 'Oui': "Yes") : (language === 'FR' ? 'Non': "No")} </TableCell>
            <TableCell > {record.start_date} </TableCell>
            <TableCell > {record.end_date} </TableCell>
            <TableCell > {record.companies_to_analyse} </TableCell>
            <TableCell > {record.controls} </TableCell>
            <TableCell > {record.sampling_pct} </TableCell>
            <TableCell > {record.sampling_threshold_low} </TableCell>
            <TableCell > {record.sampling_threshold_high} </TableCell>
        </TableRow>
    );
}
