import React, {useState, createContext, useContext} from 'react'
import axios from 'axios'
import * as apiEndPoints from '../utils/apiEndpoints'
import { GlobalContext } from './GlobalContext'

export const AuthContext = createContext()

export function AuthContextProvider(props) {
    
    const [ loadingLogin,  setLoadingLogin ] = useState(false)
    const [ loginError, setLoginError ] = useState(false)
    const [ loginErrorMessage, setLoginErrorMessage ] = useState("")
    const [ user, setUser ] = useState()

    const { language } = useContext(GlobalContext)

    async function logoutUser(){

        // retrieve token
        const token = localStorage.getItem("Token")
        
        localStorage.clear()
        setUser(undefined)

        const options = {
            headers:{
                "Authorization": `Token ${token}`,
                "Content-Type":"application/json",
                "accept": "application/json"
            }
        }

        try {
            const res = await axios.get(apiEndPoints.TOKEN_DESTROY_API_ENDPOINT, options)
            const response = await res.data
        } catch (err) {
            console.log(err)
        }
    }

    async function isUserAuthenticated() {

        // if user exists, exit function
        if (user) { return true }

        // retrieve token
        const token = localStorage.getItem("Token")

        // set the options (headers)
        const options = {
            headers:{
                "Authorization": `Token ${token}`,
                "Content-Type":"application/json",
                "accept": "application/json"
            }
        }
        
        try {
            setLoadingLogin(true)
            const response = await axios.get(apiEndPoints.CURRENT_USER_DETAILS_API_ENDPOINT, options)
            const res = await response.data
            setUser(res)

        } catch( err ) {
            setUser(undefined)
            localStorage.clear()

        } finally {
            setLoadingLogin(false)
        }
    }

    async function loginUser(username, password) {
        
        // check if both username and password were provided
        if (!username | !password) {
            setLoginError(true)
            setLoginErrorMessage(language === "EN" ? "Please provide a valid Username and Password !" : "Merci de saisir un identiant et mot de passe valides !")
            return undefined
        }

        // clear local stroge before fetch
        localStorage.clear()
        
        const options = {
            headers : {
                "Content-Type":"application/json",
                "accept": "application/json"
            }
        }

        const body = {
            username,
            password
        }

        try {
            setLoadingLogin(true)
            setLoginError(false)

            const response = await axios.post(apiEndPoints.AUTHENTICATION_API_ENDPOINT, body, options)
            const res = await response.data
            
            setUser(res)
            
            localStorage.setItem("Token", res.token)
            localStorage.setItem("Username", res.username)
            localStorage.setItem("lang", res.preferred_language)

        } catch (err) {
            setLoginError(true)
            setLoginErrorMessage(language === "EN" ? "Could not authenticate user with provided Username and Password !" : "Nous n'avons pas pu vous identifiez avec les identifiants fournis !")

        } finally {
            setLoadingLogin(false)
        }
    }

    const contextData = {
        user,
        loginUser,
        logoutUser,
        loadingLogin,
        loginError,
        setLoginError,
        loginErrorMessage,
        isUserAuthenticated
    }

    return (
        <AuthContext.Provider value={contextData}>
            {props.children}
        </AuthContext.Provider>
    )
    
}
