import React, {useState, createContext, useEffect} from 'react'

export const GlobalContext = createContext()

export function GlobalContextProvider(props) {    

    const [ language, setLanguage ] = useState()

    useEffect(() => {
        const lang = localStorage.getItem("lang")
        lang ? setLanguage(lang) : setLanguage("EN")
    }, [])

    const gtColors = {
        primary:'#4F2D7F',
        secondary:'#CBD4BC',
        teal: '#00A7B5',
        green: '#9BD732',
        orange: '#FF7D1E'
    }

    const contextData = {
        language, 
        setLanguage,
        gtColors,
    }

    return (
        <GlobalContext.Provider value={contextData}>
            {props.children}
        </GlobalContext.Provider>
    )

}
