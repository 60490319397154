import newYork from "../media/newYork.jpg"

export const backgroundStyle = {
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${newYork})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
}

export const layerStyle = {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
}

export const titleStyle = {
    paddingTop: "70px",
    fontWeight:"bolder"
}

export const paperStyle = {
    maxWidth:600, 
    minHeight:380, 
    background:'rgb(255, 255, 255, 0.8)', 
    margin:"70px auto"
}

export const paperHeadStyle = {
    fontWeight:"bold", 
    color:"white", 
    background:"#4F2D7F", 
    padding:"8px"
}

