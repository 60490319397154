import axios from 'axios'
import * as apiEndPoints from "./apiEndpoints";
import axiosInstance from "./axiosInstance";
import FileSaver from 'file-saver';

export async function uploadFile(file, setUploadProgress, params) {

    if (!file){
        console.log("No file to upload !")
        return 
    }

    // retrive token from local storage    
    const token = localStorage.getItem("Token")

    const formData = new FormData()
    formData.append('file', file)

    let url = apiEndPoints.FILE_UPLOAD_API_ENDPOINT 

    if (params) {
        const query_params = Object.entries(params).map(entry => entry.join("=")).join("&")
        url = url + "?" + query_params
    }

    try{
        await axios.post(url, formData, {
            headers:{
                "Content-Type":"multipart/form-data",
                "Authorization": `Token ${token}`,
            }, 
            onUploadProgress: (progressEvent) => {
                
                setUploadProgress(
                    Math.round(
                        100 * parseInt(progressEvent.loaded)/parseInt(progressEvent.total)
                        )
                    )
            }
        })

        setUploadProgress(undefined)

    } catch(err) {
        console.log("error !")
        console.log(err)
    }
}

export async function getUsersList() {

    const url = apiEndPoints.USERS_LIST_API_ENDPOINT

    try {
        const res = await axiosInstance.get(url)
        const response = await res.data
        return response

    } catch(err) {
        console.log(err)
        return []
    }
}


export async function deleteUser(username) {

    if (!username){
        return {
            "success":false,
            "message":"no username provided"
        }
    }

    const url = apiEndPoints.DELETE_USER_API_ENDPOINT + "?username=" + username

    try {
        const res = await axiosInstance.delete(url)
        const response = await res.data
        return response

    } catch(err) {
        console.log(err)
        return err.response.data
    }
}


export async function createUser( data ) {

    if (!data){
        return {
            "success":false,
            "message":"no user data provided"
        }
    }

    const url = apiEndPoints.CREATE_USER_API_ENDPOINT

    const body = {
        username: data.username,
        password: data.password,
        password2: data.password2,
        first_name: data.firstName,
        last_name: data.lastName,
        company: data.company,
        email: data.email,
        title:data.title,
        role: data.role,
        preferred_language: data.preferredLanguage
    }

    try {
        const res = await axiosInstance.put(url, body)
        const response = await res.data
        return response

    } catch(err) {
        console.log(err)
        return err.response.data
    }
}


export async function updateUser( data ) {

    if (!data){
        return {
            "success":false,
            "message":"no data provided"
        }
    }

    const url = apiEndPoints.UPDATE_USER_API_ENDPOINT

    const body = {
        username: data.username,
        role: data.role
    }

    try {
        const res = await axiosInstance.patch(url, body)
        const response = await res.data
        return response

    } catch(err) {
        console.log(err)
        return err.response.data
    }
}


export async function createLaunchRecord( body ) {
    
    if (!body){
        return {
            "success":false,
            "message":"no data provided"
        }
    }

    const url = apiEndPoints.CREATE_LAUNCH_RECORD_API_ENDPOINT

    try {
        const res = await axiosInstance.post(url, body)
        const response = await res.data
        return {
            "success":true, 
            ...response
        }

    } catch(err) {
        console.log(err)
        return { 
            "success":false, 
            ...err.response.data
        }
    }
}


export async function getLaunchRecords() {

    const url = apiEndPoints.GET_LAUNCH_RECORDS_API_ENDPOINT

    try {
        const res = await axiosInstance.get(url)
        const response = await res.data
        return response

    } catch(err) {
        console.log(err)
        return []
    }
}


export async function getFaqs() {

    const url = apiEndPoints.GET_FAQS_API_ENDPOINT

    try {
        const res = await axiosInstance.get(url)
        const response = await res.data
        return response

    } catch(err) {
        console.log(err)
        return []
    }
}


export async function getFaqAttachment(id) {
    
    if (!id){
        return {
            success:false,
            message:"No FAQ id provided !"
        }
    }
    
    const url = apiEndPoints.GET_FAQ_ATTACHMENT_API_ENDPOINT + "?id=" + id

    const options = {
        responseType:"blob", // important
    }

    try {
        const res = await axiosInstance.get(url, options)
        const fileName = res.headers["file-name"]
        FileSaver.saveAs(res.data, fileName)  // external dependencie

    } catch(err) {
        console.log(err)
        return {
            success:false,
            message:"An error occured",
            ...err.response.data
        }
    }
}


export async function getAnalysisLogs() {

    const url = apiEndPoints.GET_ANALYSIS_LOGS_API_ENDPOINT

    try {
        const res = await axiosInstance.get(url)
        const response = await res.data
        return response

    } catch(err) {
        console.log(err)
        return []
    }
}


export async function getFilesLabelsOverride() {

    const url = apiEndPoints.GET_FILES_LABELS_OVERRIDES_API_ENDPOINT

    try {
        const res = await axiosInstance.get(url)
        const response = await res.data
        return response

    } catch(err) {
        console.log(err)
        return []
    }
}


export function addTimestampToFileName(fileName, timestamp) {
    
    let fileNameList = fileName.split(".")

    // if filename does not contain extention
    if (fileNameList.length === 1) {
        return fileName + "_" + timestamp
    }
    
    let nameList = fileNameList.slice(0, -1) 
    let extention = fileNameList.at(-1)

    nameList.push(`_${timestamp}`)
    
    let newFileName = nameList.join("")
    
    return newFileName + "." + extention

}