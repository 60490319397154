import React, { useContext, useState, useRef, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, LinearProgress, Alert } from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';
import validator from 'validator'
import { updateUser } from '../utils/utilities';


export default function UsersModifyDialogForm( {open, setOpen, user, getUsers }) {

    const { language, gtColors } = useContext(GlobalContext)

    const [ loading, setLoading ] = useState(false)
    const [ userModifySuccess, setUserModifySuccess] = useState()
    const [ userModifyAPIMessage, setUserModifyAPIMessage] = useState()
    const [ role, setRole ] = useState(null)
    const [ addButtonActive, setAddButtonActive ] = useState(false)

    function handleClose (){
        setOpen(false);
        setRole(null)
    };

    useEffect(() => {
        setAddButtonActive((role === "USER" | role === 'ADMIN'))
    }, [role])

    async function handleUserModifyButtonClick(){

        const body = {
            username: user.username,
            role: role
        }

        setLoading(true)
        const res = await updateUser(body)
        setLoading(false)
        
        setUserModifySuccess(Boolean(res.success))
        if (res.success !== undefined) {
            setUserModifyAPIMessage(res.message)
        } else {
            const message_key = Object.keys(res)[0]
            const message_value = Object.values(res)[0][0]
            setUserModifyAPIMessage((message_key !== 'non_field_errors' ? message_key + ' - ' + message_value : message_value))
        }
        
        if (res.success) {
            setTimeout(() => {
                setUserModifySuccess()
                setUserModifyAPIMessage()
                setOpen(false)
                setRole(null)
                getUsers()
            }, 2000)
        }
    }

    return (
    <div>
        <Dialog open={open} onClose={handleClose} maxWidth="xs">
            <DialogTitle color={gtColors.teal}>
                {language === 'FR' ? `Modifier le rôle de l'utilisateur (${user.username})`  : `Edit User Role  (${user.username})`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                {language === 'FR' ? 
                    `Merci de siasir le nouveau 'rôle' que vous souhaiez attribuer à ${user.first_name} ${user.last_name} : `
                    : `Pleas select the new role you want to grant to ${user.first_name} ${user.last_name} :`}
                </DialogContentText>
                <Grid container width="100%" marginTop={1} spacing={1}>
                    <Grid item xs={12}>
                        <TextField margin="dense" label={language === 'FR' ? "Nouveau Rôle" : "New Role"} select type="Select" value={role} onChange={(e) => setRole(e.target.value)} fullWidth color="gtTeal">
                            <MenuItem key="USER" value="USER">
                                User
                            </MenuItem>
                            <MenuItem key="ADMIN" value="ADMIN">
                                Admin
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            {loading && <LinearProgress color="gtTeal"/>}
            <DialogActions style={{marginRight:"25px", marginBottom:"10px"}}>
                <Button onClick={handleClose} variant='outlined' color="gtTeal">
                    {language === "FR" ? "Annuler" : "Cancel"}
                </Button>
                <Button disabled={!addButtonActive} onClick={handleUserModifyButtonClick} variant='contained'  color="gtTeal">
                    {language === "FR" ? "Modifier" : "Edit"}
                </Button>
            </DialogActions>
            {userModifySuccess !== undefined && (
            <Alert severity={userModifySuccess ? "success" : "error"}>
                {language === "FR" ? 
                    (userModifySuccess ? "Rôle mis à jour avec succès" : userModifyAPIMessage ) 
                    : (userModifySuccess ? "User role updated successfully" : userModifyAPIMessage)
                }
            </Alert>)
            }
        </Dialog>
    </div>
    );
}