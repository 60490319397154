import React, { useContext, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router';
import MainLayout from './layouts/MainLayout';

import LoginPage from './pages/LoginPage';
import MenuPage from './pages/MenuPage';
import UsersPage from './pages/UsersPage';
import HelpPage from './pages/HelpPage';
import AnalysisPage from './pages/AnalysisPage';
import Dashboard from './pages/Dashboard';

import { AuthContext } from './context/AuthContext'

function App() {

  const { isUserAuthenticated, user } = useContext(AuthContext)
  const history = useHistory()

  async function isAuth() {
    const isAuthenticated = await isUserAuthenticated()
    return isAuthenticated
  }

  // check if user is still auth after page refreshing
  useEffect(() => {

    isAuth()

    if (!user) {
      history.push("/login")
    }

  }, [])

  function handleOnClickEvent(e) {
    localStorage.setItem("currentPath", history.location.pathname)
  }

  return (
    <div className="App" onClick={handleOnClickEvent}>
      <Switch>

        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/menu" component={MenuPage} />
        <Route exact path="/" component={LoginPage} />
        {/* <Route exact path="/logout" component={LogoutPage} /> */}

        <MainLayout>
          <Route exact path="/analysis" component={AnalysisPage} />
          <Route exact path="/users" component={UsersPage} />
          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/dashboard" component={Dashboard} />
          {/* <Route path="/" component={UnknownPage}/> */}
        </MainLayout>
      </Switch>
    </div>
  );
}

export default App;
