import newYork from "../media/newYork.jpg"

export const backgroundStyle = {
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${newYork})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
}

export const layerStyle = {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
}

export const titleStyle = {
    fontWeight:"bolder",
    marginTop:"40px"
}