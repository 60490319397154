import React, { useState, useContext } from 'react';
import { Card, CardHeader, CardContent, Typography, CardActions, Button, IconButton, LinearProgress, Tooltip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { getFaqAttachment } from '../utils/utilities';

import { GlobalContext } from '../context/GlobalContext';


export default function HelpPageCard({data}) {

    const { language } = useContext(GlobalContext)
    const [ loading, setLoading ] = useState(false)

    function getSubheader(language) {
        
        const author = data.author
        const date = new Date(data.creation_date).toLocaleDateString()
        
        if (language === 'FR') {
            return `Par ${author}, le ${date.slice(0, 10)}.`
        } 

        return `By ${author}, ${date.slice(0, 10)}.`
    }

    async function downloadAttachedFile() {
        // logic here
        setLoading(true)
        
        await getFaqAttachment(data.id)

        setLoading(false)
    }

    return (
        <Card elevation={5}>
            {loading && <LinearProgress/>}
            <CardHeader
                title={language === "FR" ? data.title_fr : data.title_en}
                subheader={getSubheader(language)}
                action={data.attachment && <Tooltip title={language === "FR" ? "Télécharger le document joint" : "Download the attached document"}><IconButton color='primary' onClick={downloadAttachedFile} disabled={loading}><AttachFileIcon/></IconButton></Tooltip>}
            />
            <CardContent style={{marginTop:"-25px"}}>
                <Typography paragraph gutterBottom>
                    <p dangerouslySetInnerHTML={{__html:language === "FR" ? data.body_fr : data.body_en}}/>
                </Typography>
            </CardContent>
            {data.attachment && 
            <CardActions style={{marginTop:"-25px"}}>
                <Button startIcon={<AttachFileIcon/>} onClick={downloadAttachedFile} disabled={loading}>
                    {language === "FR" ? "Télécharger le document joint" : "Download the attached document"}
                </Button>
            </CardActions>}
        </Card>
    );
}
