// // for local dev
// const API_URL = "http://localhost:8000"

// for deployment on Digital Ocean
const API_URL = "https://gt-solution-data.com"


// Backend endpoints
export const AUTHENTICATION_API_ENDPOINT = API_URL + "/backend/api/api-token-auth/"
export const TOKEN_DESTROY_API_ENDPOINT = API_URL + "/backend/api/api-token-auth/logout/"
export const CURRENT_USER_DETAILS_API_ENDPOINT = API_URL + "/backend/api/currentUserDetails/";
export const FILE_UPLOAD_API_ENDPOINT = API_URL + "/backend/api/fileUpload/";
export const USERS_LIST_API_ENDPOINT = API_URL + "/backend/api/getUsersList/";
export const CREATE_USER_API_ENDPOINT = API_URL + "/backend/api/createUser/";
export const UPDATE_USER_API_ENDPOINT = API_URL + "/backend/api/updateUser/";
export const DELETE_USER_API_ENDPOINT = API_URL + "/backend/api/deleteUser";
export const CREATE_LAUNCH_RECORD_API_ENDPOINT = API_URL + "/backend/api/createLaunchRecord/";
export const GET_LAUNCH_RECORDS_API_ENDPOINT = API_URL + "/backend/api/getLaunchRecords/";
export const GET_FAQS_API_ENDPOINT = API_URL + "/backend/api/getFAQs/";
export const GET_FAQ_ATTACHMENT_API_ENDPOINT = API_URL + "/backend/api/getFAQAttachment";
export const GET_ANALYSIS_LOGS_API_ENDPOINT = API_URL + "/backend/api/getAnalysisLogs";
export const GET_FILES_LABELS_OVERRIDES_API_ENDPOINT = API_URL + "/backend/api/getFilesLabelsOverride";


