import React, { useContext, useState } from 'react'
import { FormControl, CircularProgress, TextField } from '@mui/material'
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { GlobalContext } from '../context/GlobalContext';


export default function FileUploader({inputLabel, setFile, uploadProgress}) {

    const { language } = useContext(GlobalContext)
    const [fileName, setFileName] = useState("")

    return (
        <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            
            <FormControl 
                variant="standard" 
                margin='normal' 
                aria-autocomplete='none' 
                style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", gap:"20px"}}
            >
                <TextField
                    fullWidth
                    label={inputLabel}
                    value={fileName}
                    type="text"
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                {uploadProgress && <CircularProgress variant="determinate" value={uploadProgress} />}
                
                <Button color="primary" variant="contained" component="label" style={{width:"60%"}}>
                    {language === "EN" ? "Select a file" : "Sélectionner un fichier"}
                    <input 
                        type='file' 
                        hidden
                        onChange={(e) => {setFile(e.target.files[0]); setFileName(e.target.files[0].name);}}
                    />
                </Button>

            </FormControl>
        </div>
    )
}

FileUploader.prototype = {
    inputLabel: PropTypes.string,
    setFile: PropTypes.func
}