import React, {useContext, useState, useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, LinearProgress} from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';
import { getLaunchRecords } from '../utils/utilities';
import AnalysisPageAnalysisLaunchHistoryRow from './AnalysisPageAnalysisLaunchHistoryRow';


export default function AnalysisPageLaunchHistoryTable() {

    const { language, gtColors } = useContext(GlobalContext)

    const [ loading, setLoading ] = useState(false)
    const [ launchRecords, setLaunchRecords ] = useState([])

    async function getRecords() {
        setLoading(true)
        const records = await getLaunchRecords()
        setLaunchRecords(records.filter((rec) => rec.launch_type !== 'filesUpload'))
        setLoading(false)
    }

    useEffect(() => {
        getRecords()        
    }, [])


    return (
        <TableContainer component={Paper} elevation={4}>
            {loading && <LinearProgress color='gtOrange'/>}
            <Table sx={{ minWidth: 750 }} aria-label="simple table" size='small'>
                <TableHead>
                    <TableRow style={{backgroundColor:gtColors.primary}}>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Date Création' : 'Creation Date'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Référence' : 'Reference'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Auteur' : 'Author'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Type Lancement' : 'Launch Type'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Statut' : 'Status'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Détail Statut' : 'Status Detail'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Date / Heure Exécution Planifiée' : 'Planned Execution Date / Time'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Exécution Immédiate' : 'Immediate Execution'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? "Date début d'analyse" : 'Analysis Start Date'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? "Date fin d'analyse" : 'Analysis End Date'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Sociétés à Analyser' : 'Companies to Analyse'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Contrôles' : 'Controls'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Echantillon %' : 'Sampling %'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Seuil bas' : 'Sample Threshold Low'}</TableCell>
                        <TableCell style={{color:"white"}}>{language === 'FR' ? 'Seuil haut' : 'Sample Threshold High'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {launchRecords.map((record) => (                        
                        <AnalysisPageAnalysisLaunchHistoryRow key={record.id + "-" + record.reference} record={record} language={language} />     
                    ))}                    
                </TableBody>
            </Table>
            {loading && <LinearProgress color='gtOrange'/>}
        </TableContainer>
    );
}
