import React, {Fragment, useContext, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip} from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UsersDeleteConfirmDialog from './UsersDeleteConfirmDialog';
import UsersModifyDialogForm from './UsersModifyDialogForm';

export default function UsersTableRow( { rowKey, userItem, getUsers } ) {

    const { language, gtColors } = useContext(GlobalContext)
    const [ deleteDialogOpen, setDeleteDialogOpen ] = useState(false)
    const [ modifyDialogOpen, setModifyDialogOpen ] = useState(false)

    return (
        <Fragment>
            <UsersModifyDialogForm open={modifyDialogOpen} setOpen={setModifyDialogOpen} user={userItem} getUsers={getUsers}/>
            <UsersDeleteConfirmDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} username={userItem.username} getUsers={getUsers}/>
            <TableRow key={rowKey} hover>
                <TableCell style={{fontWeight:"bold"}}>{userItem.username}</TableCell>
                <TableCell>{userItem.last_name}</TableCell>
                <TableCell>{userItem.first_name}</TableCell>
                <TableCell>{userItem.email}</TableCell>
                <TableCell>{userItem.title}</TableCell>
                <TableCell>{userItem.company ? userItem.company.name : null}</TableCell>
                <TableCell>{userItem.role}</TableCell>
                <TableCell>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <Tooltip title={language === "FR" ? "Modifier le rôle de l'utilisateur" : "Edit User Role"}>
                            <IconButton onClick={() => setModifyDialogOpen(true)}>
                                <EditIcon color='gtTeal'/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={language === "FR" ? "Supprimer l'utilisateur" : "Delete User"}>
                            <IconButton onClick={() => setDeleteDialogOpen(true)}>
                                <DeleteForeverIcon color='gtOrange'/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </TableCell>
            </TableRow>
        </Fragment>
  );
}
