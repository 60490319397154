import React, { useContext }  from 'react'
import { AuthContext } from '../context/AuthContext';

function Dashboard() {

    const { user } = useContext(AuthContext)

    return (
        <div>
            <iframe
                title="superset dashboard"
                src={user.company.dashboard_url}
                width="600"
                height="400"
                frameborder="0"
            >
            </iframe>
        </div>
    )
}

export default Dashboard