import React, {useContext, useEffect, useState, Fragment} from 'react'
import { Grid, Typography } from '@mui/material'
import { AuthContext } from '../context/AuthContext'
import { GlobalContext } from '../context/GlobalContext'
import UsersTable from '../components/UsersTable'
import { getUsersList } from '../utils/utilities'
import { UsersAddButton } from '../components/UsersAddButton'

export default function UsersPage() {

    const { user } = useContext(AuthContext)
    const { language } = useContext(GlobalContext)
    const [ usersList, setUsersList ] = useState([])
    const [ loading, setLoading ] = useState(false)

    async function getUsers() {
        setLoading(true)
        const res =  await getUsersList()
        setUsersList(res)
        setLoading(false)
    } 

    useEffect(() => {
        getUsers()
    }, [])

    return (
        <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>

            { (user && user.role === "USER") ? <Typography variant='h3' align="center" marginTop={4} fontWeight="bold">{language === 'EN' ? "Unauthorized User" : "Vous n'êtes pas autorisé à accéder à cette page"}</Typography>
            :(  <Fragment>
                    <Typography variant='h3' align="center" marginTop={4} fontWeight="bold">
                        {language === 'EN' ? "Users Management" : "Gestions des utilisateurs"}
                    </Typography>
                    <Grid container maxWidth={1050} justifyContent="end">
                        <Grid item marginBottom={2}>
                            <UsersAddButton getUsers={getUsers}/>
                        </Grid>
                        <Grid item xs={12}>
                            <UsersTable usersList={usersList} getUsers={getUsers} loading={loading}/> 
                        </Grid>
                    </Grid>
                </Fragment>
            )}
        </div>
    )
}
