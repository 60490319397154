import { createTheme } from '@mui/material/styles';

export const grantThorntonTheme = createTheme({
  palette: {
        primary: {
            main: '#4F2D7F',
        },
        secondary: {
            main: '#CBD4BC',
            contrastText: 'white'
        },
        gtTeal: {
            main: '#00A7B5',
            contrastText: 'white'
        },
        gtGreen: {
            main: '#9BD732',
            contrastText: 'white'
        },
        gtOrange: {
            main: '#FF7D1E',
            contrastText: 'white'
        }        
    },
});