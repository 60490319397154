import React, { useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';

export default function AnalysisPageSnackBarAlert( { open, setOpen, alertType }) {

    const { language } = useContext(GlobalContext)

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical:"bottom", horizontal:"center"}}>
            <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                { alertType === "success" && (language === 'FR' ? "Lancement Analyse / Chargement fichiers enregistré avec succès !" : "Analysis launch / Files upload recorded successfully !")}
                { alertType === "error" && (language === 'FR' ? " Une erreur est survenue !" : "An error occurred !")}
            </Alert>
        </Snackbar>
    );
}
