import React, { useContext, useState, useRef } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, LinearProgress, Alert } from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';
import validator from 'validator'
import { createUser } from '../utils/utilities';
import Flags from 'country-flag-icons/react/3x2'

export default function UsersAddDialogForm( {open, setOpen, user, getUsers }) {

    const { language, gtColors } = useContext(GlobalContext)

    const [ passwordError, setPasswordError ] = useState(false)
    const [ emailError, setEmailError ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ userAddSuccess, setUserAddSuccess] = useState()
    const [ userAddAPIMessage, setUserAddAPIMessage] = useState()
    const [ addButtonActive, setAddButtonActive ] = useState(false)
    const [ role, setRole ] = useState("USER")
    const [ preferredLanguage, setPreferredLanguage ] = useState("FR")

    const usernameRef = useRef()
    const passwordRef = useRef()
    const password2Ref = useRef()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const emailRef = useRef()
    const titleRef = useRef()

    function handleEmailValidation (e) {
        const email = e.target.value
        if (email) {
            setEmailError(!validator.isEmail(e.target.value))
        } else {
            setEmailError(false)
        }        
    } 

    function handlePassword2Match (e) {
        const password2 = e.target.value
        const password = passwordRef.current.value

        if (password2 && password) {
            setPasswordError(!(password2 === password))
        } else {
            setPasswordError(false)
        }        
    } 

    function handleClose (){
        setOpen(false);
    };

    async function handleUserAddButtonClick(){

        const body = {
            username: usernameRef.current.value,
            password: passwordRef.current.value,
            password2: password2Ref.current.value,
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            company: user.company_id,
            email: emailRef.current.value,
            title: titleRef.current.value,
            role: role,
            preferredLanguage: preferredLanguage
        }

        setLoading(true)
        const res = await createUser(body)
        setLoading(false)
        
        setUserAddSuccess(Boolean(res.success))
        if (res.success !== undefined) {
            setUserAddAPIMessage(res.message)
        } else {
            const message_key = Object.keys(res)[0]
            const message_value = Object.values(res)[0][0]
            setUserAddAPIMessage((message_key !== 'non_field_errors' ? message_key + ' - ' + message_value : message_value))
        }
        
        if (res.success) {
            setTimeout(() => {
                setUserAddSuccess()
                setUserAddAPIMessage()
                setOpen(false)
                getUsers()
            }, 2000)
        }
    }

    return (
    <div>
        <Dialog open={open} onClose={handleClose} maxWidth="sm">
            <DialogTitle color={gtColors.primary}>
                {language === 'FR' ? "Ajouter un utilisateur" : "Add User"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                {language === 'FR' ? 
                    "Merci de siasir les information de l'utilisateur que vous souhaitez ajouter :" 
                    : "Please fill in the information details of the user you want to add :"}
                </DialogContentText>
                <Grid container width="100%" marginTop={1} spacing={1}>
                    <Grid item xs={6}>
                        <TextField margin="dense" inputRef={usernameRef} label={language === 'FR' ? "Identifiant" : "Username"} type="text" fullWidth autoFocus={false}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField margin="dense" error={emailError}  onChange={handleEmailValidation} inputRef={emailRef} label="Email" type="email" fullWidth autoFocus={false} autoComplete='off'/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField margin="dense" inputRef={passwordRef} label={language === 'FR' ? "Mot de passe" : "Password"}  type="password" fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField margin="dense" error={passwordError} onChange={handlePassword2Match} inputRef={password2Ref} label={language === 'FR' ? "Mot de passe (Vérification)" : "Password (check)"}  type="password" fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField margin="dense" inputRef={lastNameRef} label={language === 'FR' ? "Nom" : "Last Name"}  type="text" fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField margin="dense" inputRef={firstNameRef} label={language === 'FR' ? "Prénom" : "First Name"}  type="text" fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField margin="dense" inputRef={titleRef} label={language === 'FR' ? "Poste" : "Function Title"}  type="text" fullWidth/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField margin="dense" label={language === 'FR' ? "Rôle" : "Role"} select type="Select" value={role} onChange={(e) => setRole(e.target.value)} fullWidth>
                            <MenuItem key="USER" value="USER">
                                User
                            </MenuItem>
                            <MenuItem key="ADMIN" value="ADMIN">
                                Admin
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField margin="dense" label={language === 'FR' ? "Langue Préférée" : "Preferred Language"} select type="Select" value={preferredLanguage} onChange={(e) => setPreferredLanguage(e.target.value)} fullWidth>
                            <MenuItem key="FR" value="FR">
                                <Flags.FR title='Français' style={{width:"22"}}/>
                                <span style={{marginLeft:"10px"}}>FR</span>
                            </MenuItem>
                            <MenuItem key="EN" value="EN">
                                <Flags.GB title='English' style={{width:"22"}}/>
                                <span style={{marginLeft:"10px"}}>EN</span>
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            {loading && <LinearProgress/>}
            <DialogActions style={{marginRight:"25px", marginBottom:"10px"}}>
                <Button onClick={handleClose} variant='outlined'>{language === "FR" ? "Annuler" : "Cancel"}</Button>
                <Button onClick={handleUserAddButtonClick} variant='contained'>{language === "FR" ? "Ajouter Utilisateur" : "Add User"}</Button>
            </DialogActions>
            {userAddSuccess !== undefined && (
            <Alert severity={userAddSuccess ? "success" : "error"}>
                {language === "FR" ? 
                    (userAddSuccess ? "Utilisateur ajouté avec succès" : userAddAPIMessage ) 
                    : (userAddSuccess ? "User added successfully" : userAddAPIMessage)
                }
            </Alert>)
            }
        </Dialog>
    </div>
    );
}