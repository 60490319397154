import React, { useContext} from 'react'
import { useHistory } from 'react-router-dom'
import * as menuStyle from '../styles/menuPageStyle'
import { AuthContext } from '../context/AuthContext'
import { GlobalContext } from '../context/GlobalContext'
import Footer from '../components/Footer'
import { Grid, Typography } from '@mui/material'
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import { Fade } from '@mui/material'
import Button from '@mui/material/Button';

export default function MenuPage() {

    const history = useHistory()
    const { user, logoutUser } = useContext(AuthContext)
    const { language } = useContext(GlobalContext)


    function handleLogoutButtonClick() {
        logoutUser()
        history.replace("/login")
    }


    return (
        <Fade in timeout={800}>
        <div style={menuStyle.backgroundStyle}>
            <div style= {menuStyle.layerStyle}>
                <Grid container spacing={0}>
                    <Grid item xs={12} style={{backgroundColor:'rgba(255, 255, 255, 0.8)', padding:"20px",height:"80px", display:"flex", alignContent:"center", alignItems:"center", justifyContent:"end", gap:"10px"}}>
                        <Typography variant="h6" color="primary" noWrap fontWeight="bold">{user && user.first_name + " " + user.last_name}</Typography>
                        <Button color="primary" variant="outlined" style={{margin:"4px 2px"}} onClick={handleLogoutButtonClick}>{language === "EN" ? "Logout" : "Se Déconnecter"}</Button>
                    </Grid>
                    <Grid item xs={12} style={{backgroundColor:'rgba(255, 255, 255, 0.8)', height:"120px"}}>
                        <Typography variant='h4' color="primary" align='center' style={menuStyle.titleStyle}>
                            {language === "FR" ? "Solution Data Analytics" : "Data Analytics Solution"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{backgroundColor:'rgba(79, 45, 127, 0.85)', height:"220px"}}>
                        <Grid container spacing={0} justifyContent="space-around">
                            <Grid item xs={3} style={{display:"flex", flexDirection:"column", justifyContent:"center", marginTop:"30px", cursor:"pointer"}} onClick={() => history.push("/analysis")}>
                                <AddchartOutlinedIcon style={{color:"white", fontSize:"100", margin:"7px auto"}}/>
                                <Typography variant='h4' color="white" align='center'>
                                    {language === "FR" ? "Analyses" : "Analysis"}
                                </Typography>
                            </Grid>
                            {(user && user.role !== 'USER') && 
                                <Grid item xs={3} style={{display:"flex", flexDirection:"column", justifyContent:"center", marginTop:"30px", cursor:"pointer"}} onClick={() => history.push("/users")}>
                                    <GroupAddOutlinedIcon style={{color:"white", fontSize:"100", margin:"7px auto"}}/>
                                    <Typography variant='h4' color="white" align='center'>
                                        {language === "FR" ? "Utilisateurs" : "Users"}
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={3} style={{display:"flex", flexDirection:"column", justifyContent:"center", marginTop:"30px", cursor:"pointer"}} onClick={() => history.push("/help")}>
                                <QuizOutlinedIcon style={{color:"white", fontSize:"100", margin:"7px auto"}}/>
                                <Typography variant='h4' color="white" align='center'>
                                    {language === "FR" ? "Aide" : "Help"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{backgroundColor:'rgba(255, 255, 255, 0.8)', minHeight:"120px"}}>
                        <Grid container spacing={0} justifyContent="space-around">
                            <Grid item xs={3} style={{display:"flex", flexDirection:"column", justifyContent:"center", marginTop:"30px"}}>
                                <Typography color="black" align='center' margin="0px 40px" fontSize={18}>
                                    {language === "FR" ? "Lancer une analyse. Charger des données." : "Launch an analysis. Load data."}
                                </Typography>
                            </Grid>
                            {(user && user.role !== 'USER') && 
                                <Grid item xs={3} style={{display:"flex", flexDirection:"column", justifyContent:"center", marginTop:"30px"}}>
                                    <Typography color="black" align='center' margin="0px 40px" fontSize={18}>
                                        {language === "FR" ? "Gestion les utilisateurs et leurs droits." : "Manage users and their rights."}
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={3} style={{display:"flex", flexDirection:"column", justifyContent:"center", marginTop:"30px"}}>
                                <Typography color="black" align='center' margin="0px 40px" fontSize={18}>
                                    {language === "FR" ? "Accéder à la documentation disponible et / ou demander de l'aide." : "Get available documentation or ask for help."}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Footer />
            </div>
        </div>
        </Fade>
    )
}
